import React, { useState, useEffect } from 'react'
import fetchToken from '../data/fetch/fetchToken'
import { Registration, AcceptReport } from '../pages'
import { SetNewPassword } from '../pages/ResetPassword/components'
import RedirectTo from './RedirectTo'

const switchLink = (hash, type) => {
    switch (type) {
        case "ac":
            return <Registration match={hash} />
    
        case "rep":
            return <AcceptReport match={hash} />

        case "np":
            return <SetNewPassword match={hash} />
    
        default:
           return <RedirectTo gotopage={"/"} />
    }
}

const Routing = ({ match }) => {
      
    return(
        <React.Fragment>
            {/*match.params.hash?  <Registration match={match.params.hash} />: <RedirectTo gotopage={"/"} />*/}
            {match.params.hash && match.params.type? switchLink(match.params.hash, match.params.type): <RedirectTo gotopage={"/"} />}
        </React.Fragment>
    )
}

export default Routing