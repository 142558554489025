import React, { useState, useEffect } from 'react'
import { Typography, Button, Grid, Paper, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { NewAcceptanceReport, InstallationList, InstallerData } from './components'
import { mainStyleClasses } from '../../theme'
import { Redirect } from 'react-router-dom'

import { default as fetchInstallatorDetails } from '../../data/fetch/fetchInstallatorDetails'


const Dashboard = ({ baseLogin }) => {
    const classes = mainStyleClasses()
    const [open, setOpen] = useState({ newReport: false, legend: false })
    const [installatorData, setInstallatorData] = useState(null)

    const handleClickOpen = (type) => {
        setOpen({ ...open, [type]: true })
    }

    const handleClose = () => {
        setOpen({ newReport: false, legend: false })
    }

    const refreshInstallatorData = () => {
        fetchInstallatorDetails(baseLogin.perm).then(res => {
            if(res.status === "ok")
            setInstallatorData(res.data)
        })
    }

const saveAcceptanceReport = (values) => {
    let deviceNewList = installatorData.devices
        deviceNewList.push({acceptance_report: values})

    setInstallatorData({...installatorData, devices: deviceNewList})
}

useEffect(() => {
    let mounted = true
    if(baseLogin && baseLogin.perm)
    refreshInstallatorData()

    return () => {
        mounted = false
    }
}, [baseLogin])

    return (
        <React.Fragment>
        {!baseLogin || !baseLogin.token ? <Redirect to="/" /> : null}
            <Grid container>
                <Grid item xs={12}>
                {installatorData?<InstallerData installatorData={installatorData} />: null}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Button className={`${classes.buttonMargin}`} variant="contained" color="primary" onClick={() => handleClickOpen('newReport')}>Dodaj nowy protokół uruchomienia</Button>
                    <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.newReport} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>
                            <NewAcceptanceReport refreshInstallatorData={refreshInstallatorData} handleClose={handleClose} installatorData={installatorData} />
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                {installatorData?<InstallationList refreshInstallatorData={refreshInstallatorData} installatorName={`${installatorData.first_name} ${installatorData.last_name}`} installatorDevices={installatorData.devices} />: null}
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Dashboard