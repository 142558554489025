import { default as fetchRefreshToken } from './fetchRefreshToken'

const fetchNewAcceptanceReport = async (data) => {
    console.log(JSON.stringify(data))
    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/acceptance_reports`, {
        method: "POST",
        headers:{
          "Authorization": `Bearer ${newToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(res => res.status)
        return res
    })
    return response
}

  export default fetchNewAcceptanceReport

