import React, { useState, useEffect } from 'react'
import { Button, Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import { mainStyleClasses } from '../../../../theme'
import { RefillAcceptanceReport } from '../'
import { ConfirmSendForm } from '../RefillAcceptanceReport/components'
import { default as fetchPin } from '../../../../data/fetch/fetchPin'
import md5 from 'js-md5'

const itemsMap = (installatorDevices, handleClickOpen) => {
    // zmienić index na id acceptance report
    return installatorDevices.map((item, index) => <ListItem key={index} index={index} handleClickOpen={handleClickOpen} item={item.acceptance_reports[0]} />)
}

const ListItem = ({ item, index, handleClickOpen }) => {
    const classes = mainStyleClasses()
    const [pin, setPin] = useState(null)
    const dataDevice = JSON.parse(item.content)

    useEffect(() => {
        let mounted = true
        setPin(null)
        return () => {
            mounted = false
        }
    }, [item])

    const downloadPin = () => {
        fetchPin(dataDevice.serialNumber).then(response => setPin(response))
    }

    return(
        <TableRow className={item.status === "new"? `${classes.backgroundRed}`: item.status === "filled"? `${classes.backgroundYellow}` : `${classes.backgroundGreen}` }>
                <TableCell style={{ textAlign: "left" }}><Typography variant="body1">{index + 1}</Typography></TableCell>
                <TableCell style={{ textAlign: "left" }}><Typography variant="body1">{dataDevice.date? dataDevice.date : "nieznana"}</Typography></TableCell>
                <TableCell style={{ textAlign: "left" }}><Typography variant="body1">ul. {dataDevice.street? dataDevice.street : "nie podano"} {dataDevice.streetNumber? dataDevice.streetNumber : "nie podano"}, {dataDevice.postNumber? dataDevice.postNumber : "nie podano"} {dataDevice.city? dataDevice.city: "niepodano"}</Typography></TableCell>
                <TableCell style={{ textAlign: "left" }}>
                {item.customer_content ? 
                   <ErrorIcon style={{color: "red", display: "inline-block", margin: "0 15px 0 0"}} />
                    : null}
                <Typography style={{ display: "inline-block"}} variant="body1">{item.customer_content ? 
                    <React.Fragment>{item.customer_content }</React.Fragment>
                    : "Brak uwag"}</Typography></TableCell>
                <TableCell style={{ textAlign: "left" }}>{pin && pin.status === "ok" ? <Typography variant="body1">{pin.data.pin}</Typography>: <Button variant="contained" color="primary" type="button" 
                onClick={() => downloadPin()}>Pobierz</Button>}</TableCell>
                <TableCell style={{ textAlign: "right" }}><Button variant="contained" color="primary" type="button" 
                onClick={() => handleClickOpen("refill", dataDevice, item.id)}>{dataDevice.status === "filled"? "Szczegóły" : "Uzupełnij"}</Button></TableCell>
    </TableRow>
    )
}

const InstallationList = ({ installatorDevices, installatorName, refreshInstallatorData }) => {
    const classes = mainStyleClasses()

    const [open, setOpen] = useState({ status: false, type: null, data: null, id: null })

    const handleClickOpen = (type, data, id) => {
        setOpen({ status: true, type, data, id })
    }

    const handleClose = () => {
        setOpen({ status: false, type: null, data: null })
    }
    // console.log({installatorDevices})

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Lista instalacji</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                    <Box fontWeight="fontWeightBold">
                                        Lp
                                    </Box>
                            </TableCell>
                            <TableCell>
                                <Box fontWeight="fontWeightBold">
                                    Data wypełnienia
                                    </Box>
                            </TableCell>
                            <TableCell>
                                <Box fontWeight="fontWeightBold">
                                    Adres instalacji
                                    </Box>
                            </TableCell>
                            <TableCell>
                                <Box fontWeight="fontWeightBold">
                                    Uwagi klienta
                                    </Box>
                            </TableCell>
                            <TableCell>
                                <Box fontWeight="fontWeightBold">
                                    Hasło do konfiguracji
                                    </Box>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    
                                </Typography>
                            </TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {installatorDevices.length > 0? itemsMap(installatorDevices, handleClickOpen) : <TableCell colSpan={6}><Typography variant="body1">Nie posiadasz jeszcze żadnych instalacji. Aby rozpocząć konfigurowanie nowej instalacji kliknij "Dodaj nowy protokół uruchomienia".</Typography></TableCell>}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogContent style={{ padding: "10px 25px 25px" }}>
                            <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                                <CloseIcon />
                            </IconButton>
                            {open.data && open.data.status === "filled" ? <ConfirmSendForm values={open.data} /> :  <RefillAcceptanceReport refreshInstallatorData={refreshInstallatorData} installatorName={installatorName} handleClose={handleClose} report={open.data} id={open.id} />}
                            
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default InstallationList