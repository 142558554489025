import React from 'react';
import logo from '../../img/LogoEuros.svg'
import AppBar from '@material-ui/core/AppBar'
import { Grid } from '@material-ui/core'
import { mainStyleClasses } from '../../theme'

import { Navigation } from '../'

const Header = ({baseLogin, setBaseLogin}) => {
    const classes = mainStyleClasses()
    return (
        <AppBar position="static">
            <Grid container>
                <Grid item xs={6}>
                    <img className={classes.logo} src={logo} alt="Euros Energy"
                        title="Euros Energy"
                    />
                </Grid>
                {baseLogin && baseLogin.token? <Grid item xs={6} style={{textAlign: "right", padding: "25px"}}>
                    <Navigation baseLogin={baseLogin} setBaseLogin={setBaseLogin} />
                </Grid> : null }
            </Grid>
        </AppBar>
    )
}

export default Header;