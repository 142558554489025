import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

const ReportAccepted = () => {
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h1">Protokół pierwszego uruchomienia został już zamknięty.</Typography>
                    <Typography variant="h3">Zapraszamy do pobrania aplikacji mobilnej do zarządzania pompą. Hasło do aplikacji możesz ustanowić za pomoca poniższego przycisku.</Typography>
                    <br /><Button variant="contained" color="primary" type="button" component={Link} to={{ pathname: "https://cloud.eurosenergy.com:8801/" }} target="_blank">Ustanowienie hasła</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ReportAccepted