const fetchResetPassFirst = async (email) => {
    const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/installators/reset_password/first/${email}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => {
        if (res.ok) {
            return res.json()
        } else {
            throw new Error("Niepoprawny adres e-mail")
        }
    })


    return response
}

export default fetchResetPassFirst