import React from 'react'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const Navigation = ({baseLogin, setBaseLogin}) => {

    const handleLogout = () => {
        setBaseLogin({ type: "logout" })
        sessionStorage.removeItem("APIToken")
        sessionStorage.removeItem("APIToken-refresh")
        sessionStorage.removeItem("Expiration")
    }

    return (
        <React.Fragment>
            <Button onClick={() => handleLogout()} component={Link} to="/"
                variant="contained" color="primary">Wyloguj</Button>
            
        </React.Fragment>
    )
}

export default Navigation