const fetchLogin = async (login, password) => {
    const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/login?email=${login}&password=${password}`, {
      method: "GET"
    }).then(response => {
      if (response.ok) {
          return response.json()
      } else {
          throw new Error("Niepoprawne dane logowania")
      }
  })
      .then(res => {
        sessionStorage.setItem('APIToken', res.access_token)
        sessionStorage.setItem('APIToken-refresh', res.refresh_token)
        return res
      }).then(res => res)
 
      return response
  }


  export default fetchLogin