import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { Typography, Grid } from '@material-ui/core'

const Comments = ({ required }) => {


    return (
        <React.Fragment>
            <Typography variant='h3'>Uwagi:</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Field
                        name="comments"
                        component={TextField}
                        label="Dodatkowe uwagi"
                        multiline
                        fullWidth
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Comments;