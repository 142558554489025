//import { default as fetchRefreshToken } from './fetchRefreshToken'

const fetchAcceptanceReport = async (token, type, data) => {
    let res

    if (type === "GET") {
        res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/acceptance_report/token/${token}`, {
            method: type,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.json())

    } else if (type === "PUT") {
        res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/acceptance_report/token/${token}`, {
            method: type,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => res.json())
    }

    return res
}

export default fetchAcceptanceReport

