import React, { useState, useEffect } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { mainStyleClasses } from '../../../../theme'
import OptionList from './components/WizardPages/DeviceData/OptionList'

import { Wizard, Information, DeviceVerification, DeviceData, InstallationData } from './components'
import { default as fetchNewAcceptanceReport } from '../../../../data/fetch/fetchNewAcceptanceReport'
import { errorMessage } from '../validation'

const required = value => (value ? undefined : 'Pole obowiązkowe')

const NewAcceptanceReport = ({ installatorData, handleClose, refreshInstallatorData }) => {
    const classes = mainStyleClasses()

    const [verifyDevice, setVerifyDevice] = useState(null)
    const [initialValues, setInitialValues] = useState(null)
    const [error, setError] = useState({ status: false, message: "" })

    const modelSwitch = device_sn => {
        const paternFAI = /^fai$/i
        const paternEG = /^eg$/i

        if (paternFAI.test(device_sn.slice(0, 3))) {
            return "ATMO"
        } else if (paternEG.test(device_sn.slice(0, 2))) {
            return "GEO"
        } else {
            return "Urządzenie nierozpoznane"
        }
    }

    const dataPack = data => {

        let dataSavePack = {
            key: data.key,
            device_sn: data.serialNumber,
            phone: data.phone,
            content: JSON.stringify(data)
        }
        if (data.modelType === "ATMO"){
            dataSavePack = {...dataSavePack, outside_sn: data.serialNumberOutsideATMO}
        }

        return dataSavePack
    }

    const onSubmit = data => {

// saveAcceptanceReport to funkcja pomocnicza, żeby dodany protokół pojawiał się w panelu, bez wysyłki
// docelowo odświeżanie danych instalatora poprzez stuknięcie do bazy
dataPack(data)
        // saveAcceptanceReport(data)
        // fetchNewAcceptanceReport(dataPack(data)).then(res => {
        //     if(res === 200){
        //         refreshInstallatorData()
        //         setError({ status: false, message: "" })
        //         handleClose()
        //     } else {
        //         setError({ status: true, message: errorMessage(res) })
        //     }
        // })
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="h1">Protokół uruchomienia</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="body1">Rozpoczęcie konfiguracji urządzenia</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="body2">* Wszystkie widoczne pola są obowiązkowe</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <DeviceVerification setError={setError} refreshInstallatorData={refreshInstallatorData} handleClose={handleClose} setVerifyDevice={setVerifyDevice} installatorData={installatorData.phone} required={required} />
                {error.status ? <Grid container direction="row"><Grid item xs={12}><Typography variant="body2" style={{ color: "red" }}>{error.message}</Typography></Grid></Grid> : null}



            </Grid>
        </React.Fragment>
    )
}

export default NewAcceptanceReport