import React from 'react'
import { Redirect } from 'react-router-dom'

const RedirectTo = ({ gotopage }) => {

    return(
        <>
            {gotopage? <Redirect to={gotopage} /> : null}
        </>
    )
}

export default RedirectTo