const errorMessage = (status) => {
    switch (status) {
        case 403: 
            return "Nie jesteś zalogowany."
        case 404: 
            return "Nie znaleziono protokołu odbioru."
        case 411:
            return "Urządzenie nie istnieje w bazie."
        case 412:
            return "Użytkownik nie istnieje."
        case 414:
            return "Szukany protokół odbioru nie istnieje."
        case 415:
            return "Urządzenie przypisane do innego instalatora."
        case 416:
            return "Nie można już edytować protokołu. Protokół został wysłany do akceptacji klienta."
        case 417: 
            return "Nie można już edytować protokołu. Protokół został zaakceptowany przez klienta."
        case 418: 
            return "Protokół odbioru został już wypełniony."
        case 419:
            return "Błąd serwera."
        case 420:
            return "Podany token nie istnieje."
        case 422:
            return "Błędny numer telefonu."
        case 423:
            return "Nie istnieje jednostka zewnętrzna o podanym numerze seryjnym."
        case 424:
            return "Jednostka zewnętrzna o podanym numerze seryjnym została przypisana do innej jednostki wewnętrznej."
    
        default:
            return "Wystąpił nieznany błąd. Skontaktuj się z administratorem."
    }
}

export default errorMessage