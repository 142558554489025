const OptionList = (option) => {
    switch (option) {
        case 'ATMO':
            return ["EUROS ATMO 11", "FOTON TECHNIK AIR 11", "EUROS ATMO 19", "FOTON TECHNIK AIR 19", "EUROS ATMO 7", "FOTON TECHNIK AIR 7", "E.ON FOTON AIR 7", "E.ON FOTON AIR 11", "E.ON FOTON AIR 19"];
        case 'GEO':
            return ["EUROS GEO 6 SH", "EUROS GEO 8 SH", "EUROS GEO 11 SH", "EUROS GEO 14 SH", "EUROS GEO 18 SH", "EUROS GEO 6 AC", "EUROS GEO 8 AC", "EUROS GEO 11 AC", "EUROS GEO 14 AC", "EUROS GEO 18 AC", "EUROS GEO ECO 8 SH", "EUROS GEO ECO 11 SH", "EUROS GEO ECO 13 SH", "EUROS GEO ECO 17 SH"];
        case 'ALLINONE':
            return ["EUROS AIR AIO 7", "E.ON FOTON AIR AIO 7", "EUROS AIR AIO 11", "E.ON FOTON AIR AIO 11"];
        default:
            return null;
    }
}
export default OptionList