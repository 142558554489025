import React, { useEffect, useState } from 'react'
import { Button, Grid, Box, Typography, Paper, Dialog, DialogContent, IconButton } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import { ConfirmSendForm } from '../Dashboard/components/RefillAcceptanceReport/components'
import { default as fetchAcceptanceRepport } from '../../data/fetch/fetchAcceptanceReport'
import { mainStyleClasses } from '../../theme'
import { CommentForm, AfterSend } from './components'

const AcceptReport = ({ match }) => {
    const classes = mainStyleClasses()
    const [report, setReport] = useState(null)
    const [open, setOpen] = useState({ status: false, customer_content: "" })
    const [acceptStatus, setAcceptStatus] = useState(null)

    const handleClickOpen = () => {
        setOpen({ ...open, status: true })
    }

    const handleClose = () => {
        setOpen({ status: false, customer_content: "" })
    }

    const acceptReport = () => {
        fetchAcceptanceRepport(match, "PUT", { customer_content: "" }).then(res => {
            if (res.status === "ok") {
                setAcceptStatus({ status: "ok", data: "accept" })
            } else {
                setAcceptStatus({ status: "error", data: "Błąd wysyłania danych" })
            }
        })
    }

    useEffect(() => {
        let mounted = true

        if (match)
            fetchAcceptanceRepport(match, "GET").then(res => setReport(res))

        return () => {
            mounted = false
        }
    }, [match])


    const blobSend = (blob) => {
        let pdfFile = new FormData()
        pdfFile.append(`file`, blob)
        pdfFile.append('file_type', 'application/pdf')
        pdfFile.append('filename', `protokol-odbioru-${JSON.parse(report.content).protocolNumber}.pdf`)
        pdfFile.append('orig_file_name', `protokol-odbioru-${JSON.parse(report.content).protocolNumber}.pdf`)

        // console.log(pdfFile)
        // fetchFile(values.protocolToken, pdfFile)
        //     .then(res => console.log(res))
    }
    return (
        <React.Fragment>
            {report && report.status === "accepted" ? <Redirect to="/protokol-zaakceptowany" /> : null}
            {acceptStatus && acceptStatus.status === "ok" ? <AfterSend report={report} match={match} /> : (
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h1">Protokół pierwszego uruchomienia</Typography>
                            <Typography variant="body1">Zaakceptuj prezentowany protokół. Jeśli nie zgadzasz się z informacjami zawartymi w poniższym protokole, prześlij nam swoje uwagi klikając na przycisk "Nie akceptuję".</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            {report && report.status === "filled" ? <ConfirmSendForm values={JSON.parse(report.content)} /> : null}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" type="button" className={`${classes.floatLeft} ${classes.buttonMargin}`} onClick={() => acceptReport()}>Akceptuję</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" type="button" className={`${classes.floatRight} ${classes.buttonMargin}`} onClick={() => handleClickOpen()}>Nie akceptuję</Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
            <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open.status} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ padding: "10px 25px 25px" }}>
                    <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                        <CloseIcon />
                    </IconButton>
                    <CommentForm match={match} setAcceptStatus={setAcceptStatus} handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default AcceptReport