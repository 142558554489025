import React, { Fragment } from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import Roboto from '../CreatePdf/Roboto-Regular.ttf'

Font.register({ family: 'Roboto', src: Roboto })

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff',
        fontSize: "11px",
        fontFamily: 'Roboto',
        fontWeight: 400,
        padding: "40px",
        color: "#5a5a5a"
    },
    text: {
        margin: "5px auto",
    },
    min: {
        margin: "auto", marginTop: 10, fontSize: "8px", paddingLeft: 10, paddingRight: 10
    },
    title: {
        fontSize: "16px",
        margin: "0 auto 20px"
    },
    table: {
        display: "table", width: "auto", borderStyle: "solid", borderWidth: 0.5, borderRightWidth: 0, borderBottomWidth: 0, borderColor: "#d3d3d3"
    },
    tableRow: {
        margin: "auto", flexDirection: "row"
    },
    tableColS: {
        width: "40%", borderStyle: "solid", borderWidth: 0.5, borderLeftWidth: 0, borderTopWidth: 0, borderColor: "#d3d3d3"
    },
    tableColM: {
        width: "60%", borderStyle: "solid", borderWidth: 0.5, borderLeftWidth: 0, borderTopWidth: 0, borderColor: "#d3d3d3"
    },
    tableCell: {
        margin: "auto", marginTop: 5, fontSize: 10, paddingLeft: 10, paddingRight: 10
    }
});
const parser = new DOMParser()
// Create Document Component
const CreatePdf = ({ values }) => (
    <Document>{console.log(values)}
        <Page size="A4" style={styles.page}>
            <Text style={styles.title}>Protokół pierwszego uruchomienia nr {values.protocolNumber}</Text>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Protokół sporządził:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.drafted}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Data sporządzenia:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.date}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Typ urządzenia:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.modelType  === "ATMO" || values.modelType  === "ALLINONE" ? "Zestaw Powietrznej Pompy Ciepła (ZPPC)" : "Zestaw Gruntowej Pompy Ciepła (ZGPC)"}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Model urządzenia:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.model}</Text>
                    </View>
                </View>
                {values.modelType === 'ATMO' || values.modelType === 'ALLINONE' ? (
                    <Fragment>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColS}>
                                <Text style={styles.tableCell}>Numer seryjny jednostki wewnętrznej:</Text>
                            </View>
                            <View style={styles.tableColM}>
                                <Text style={styles.tableCell}>{values.serialNumber? values.serialNumber : values.device_sn}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColS}>
                                <Text style={styles.tableCell}>Numer seryjny jednostki zewnętrznej:</Text>
                            </View>
                            <View style={styles.tableColM}>
                                <Text style={styles.tableCell}>{values.serialNumberOutsideATMO}</Text>
                            </View>
                        </View>
                    </Fragment>) : (
                        <View style={styles.tableRow}>
                            <View style={styles.tableColS}>
                                <Text style={styles.tableCell}>Numer seryjny:</Text>
                            </View>
                            <View style={styles.tableColM}>
                                <Text style={styles.tableCell}>{values.serialNumber? values.serialNumber : values.device_sn}</Text>
                            </View>
                        </View>
                    )}
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Miejsce Montażu:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{`ul. ${values.street} ${values.streetNumber}, ${values.postNumber} ${values.city}, ${values.postOffice}`}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Napełnienie instalacji:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.filling}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Ocena szczelności połączeń hydraulicznych:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.tightness}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Sprawdzenie wymaganego przekroju żył przewodu zasilającego:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.wireCrossSection}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Podłączenie zasilania:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.powerConnection}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Sprawdzenie zalecanego zabezpieczenia linii zasilającej pompę ciepła w rozdzielni głównej budynku:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.powerLineProtection}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Podłączenie czujników temperatury: zasobnika cwu, bufora, temperatury zewnętrznej i temperatury wewętrznej:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.sensorsConnected ? (<strong>{values.sensorsConnected.map(sensor => `${sensor}, `)}</strong>) : (<strong>czujniki nie zostały podłączone</strong>)}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Umiejscowienie czujnika temperatury zewnętrznej:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.sensorOutsideTempPlace}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Umiejscowienie czujnika temperatury wewnętrznej:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.sensorInsideTempPlace}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Wymuszenie funkcji przygotowania cwu, odpowietrzenie obiegu przygotowania cwu, kontrola przepływu w obiegu przygotowania cwu:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.preparationCwu}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Wyłączenie funkcji przygotowania cwu i wymuszenie pracy na ogrzewanie, odpowietrzenie obiegu grzewczo - chłodzącego, kontrola przepływu w obiegu grzewczo - chłodzącym:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.forceHeating}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Pierwsze czyszczenie filtrów w obiegu grzewczym:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.cleanFilter}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Kontrola temperatury krzepnięcia w obiegu grzewczym:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.controlCoagulationTemp}</Text>
                    </View>
                </View>
                {values.coagulationTemp? (<View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Temperatura krzepnięcia:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.coagulationTemp}</Text>
                    </View>
                </View>) : null}
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Załączenie bezpiecznika jednostki zewnętrznej:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.outdoorFuse}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Przywrócenie nastaw fabrycznych:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.reinstatementSettings}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Skasowanie historii alarmów, skasowanie statystyk:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.deleteHistory}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Kontrola znaku i wartości dT na wymienniku odbioru:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.dTValueControl}</Text>
                    </View>
                </View>
                {values.dTValue? (<View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Wartości dT:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.dTValue}</Text>
                    </View>
                </View>) : null }
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Procedura uruchomienia w aplikacji ATMO Monitor:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.launchAtmoMonitor}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Test panelu operatorskiego:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.testOperatorPanel}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Przeszkolenie użytkownika:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.userInstruct}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableColS}>
                        <Text style={styles.tableCell}>Uwagi:</Text>
                    </View>
                    <View style={styles.tableColM}>
                        <Text style={styles.tableCell}>{values.comments}</Text>
                    </View>
                </View>
            </View>
            <Text style={styles.min}>Elektroniczny protokół wypełniony przez {values.drafted}, w apolikacji należącej do EUROS ENERGY Sp. z o. o.</Text>
        </Page>
    </Document>
);

export default CreatePdf