import React, { useState } from 'react'
import {
    Redirect, Link
} from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { Button, Grid, Typography } from '@material-ui/core'
import fetchLogin from '../../data/fetch/fetchLogin'
import { decodeToken } from "react-jwt"

const Login = ({ baseLogin, setBaseLogin }) => {
    const [error, setError] = useState({status: false, message: ""})

    const onSubmit = async values => {
        let timeStamp = new Date()
        timeStamp = Date.now()

        if (values && values.login && values.password) {
            fetchLogin(values.login, values.password)
                .then(response => {
                    sessionStorage.setItem('Expiration', timeStamp)
                    setBaseLogin({ type: "token", payload: response.access_token })

                    return response.access_token
                }).then(response => {
                    let resPerm = decodeToken(response)
                    setBaseLogin({ type: "perm", payload: resPerm.user_id})
                }).catch(e => setError({status: true, message: e.message}))
        }
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >{baseLogin && baseLogin.token ?  (sessionStorage.getItem('Expiration')? <Redirect to="/dashboard" />:null) : null}
                <Form
                    initialValues={null}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h2" className="text-center">Logowanie</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField} type="text" name="login" label="login" fullWidth />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField} type="password" name="password" label="hasło" fullWidth />
                                </Grid>
                            </Grid>
                            {error.status? <Grid container spacing={2}><Grid item xs={12}><Typography variant="body2" style={{color: "red"}}>{error.message}</Typography></Grid></Grid> : null}
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}><br />
                                    <Button
                                        variant="contained" color="primary" type="submit"
                                        disabled={submitting}>zaloguj</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Link to="/reset" style={{color: "#005492", textDecoration: "none"}}>Nie pamiętasz hasła? Kliknij, by ustanowić nowe hasło.</Link>
                                    </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
            </Grid>
        </React.Fragment>
    )
}

export default Login