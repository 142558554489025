import { isExpired } from "react-jwt"

let newTimeStamp = new Date()
newTimeStamp = Date.now()


const fetchRefreshToken = async () => {
  const token = sessionStorage.getItem("APIToken-refresh")
  const tokenAPI = sessionStorage.getItem("APIToken")
  
  if(isExpired(tokenAPI)) {
    const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/token_refresh`, {
        method: "POST",
        headers:{
          "Authorization": `Bearer ${token}`
        }
    }).then(response => response.json())
      .then(res => {
        sessionStorage.setItem("APIToken", res.access_token)
        sessionStorage.setItem("Expiration", newTimeStamp)
       return res.access_token
      })
      return response
  } else {
      return tokenAPI
    }
  }


  export default fetchRefreshToken