import React, { useState, useReducer } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { mainStyleClasses } from '../../../../theme'

import { Wizard, FirstFilling, ElectricalConnections, SensorConnections, VentingCircuits, PreparationFirstLaunch, FirstLaunch, MainParametersRegulation, FinalActions, Comments } from './components'

import { default as fetchRefillAcceptanceReport } from '../../../../data/fetch/fetchRefillAcceptanceReport'

const switchOption = (state, action) => {
    return {
        ...state, 
        [action.type]: action.value
    }
}

const required = value => (value ? undefined : 'Pole obowiązkowe')

const RefillAcceptanceReport = ({ report, id, installatorName, refreshInstallatorData, handleClose }) => {
    const classes = mainStyleClasses()
    const [val, setValues] = useReducer(switchOption, {coagulationTemp: null, dtValue: null})

    const onSubmit = data => {

        let saveData = {
            drafted: installatorName,
            ...report,
            ...data
        }

        const content = JSON.stringify(saveData)
        fetchRefillAcceptanceReport(content, id, data.status).then(res => {
            if(res === 200)
            refreshInstallatorData()
            handleClose()
        })
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="h1">Protokół uruchomienia</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="body1">Rozpoczęcie konfiguracji urządzenia</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="body2" className={classes.error}>* Wszystkie widoczne pola są obowiązkowe</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                {report && installatorName? (<Wizard
                    initialValues={{...report, drafted: installatorName}}
                    onSubmit={onSubmit}
                >
                    <FirstFilling required={required} />
                    <ElectricalConnections required={required} />
                    <SensorConnections required={required} />
                    <VentingCircuits required={required} />
                    <PreparationFirstLaunch coagulationTemp={val.coagulationTemp} setValues={setValues} required={required} />
                    <FirstLaunch dtValue={val.dtValue} setValues={setValues} required={required} />
                    <MainParametersRegulation required={required} />
                    <FinalActions required={required} />
                    <Comments required={required} />
                    <React.Fragment></React.Fragment>
                </Wizard> ) : null}
                {/*typeof (reportStatus) === 'string' ? <p className={classes.error}>{reportStatus}</p> : null*/}



            </Grid>
        </React.Fragment>
    )
}


export default RefillAcceptanceReport