import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Typography, Button } from '@material-ui/core'

const ThankYouPage = () => {
    return (
        <Grid item xs={12}>
            <Typography variant="h2">Dziękujemy za rejestrację</Typography>
            <p>Możesz się już zalogować do swojego panelu instalacji.</p>
            <Button variant="contained" color="primary" type="button" component={Link} to='/'> Przejdź do logowania </Button>
        </Grid>
    )
}

export default ThankYouPage