import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, TextField } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    Typography,
    Grid
} from '@material-ui/core'
import formatString from "format-string-by-pattern"

const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Wartość powinna wynosić nie mniej niż ${min}`;

const maxValue = max => value =>
  isNaN(value) || value <= max ? undefined : `Wartość powinna wynosić nie wiecej niż ${max}`;

  const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')
  
    return formatString('9', onlyNumbers)
  }


const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const FirstLaunch = ({ required, setValues, dtValue }) => {
    const [dTValueControl, setDTValueControl] = useState(false)

    const handleDTValueControl = (e) => {
        setDTValueControl(e)
    }
    return (
        <>
            <Typography variant='h2'>VII. Pierwsze uruchomienie</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3'>7a. Przywrócenie nastaw fabrycznych</Typography>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Należy przywrócić nastawy fabryczne wywołując odpowiednią funkcję sterownika</FormLabel>
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="reinstatementSettings"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="reinstatementSettings"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="reinstatementSettings" /><br />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>7b. Skasowanie historii alarmów, skasowanie statystyk</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="deleteHistory"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="deleteHistory"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="deleteHistory" /><br />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>7c. Kontrola znaku i wartości dT na wymienniku odbioru</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="dTValueControl"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "dtValue", value: true})}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="dTValueControl"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                        onClick={ () => setValues({type: "dtValue", value: false})}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="dTValueControl" />
                </Grid>
                {dtValue? (<Grid item xs={12} sm={6}>
                    <Field name="dTValue"
                        component={TextField}
                        type="text"
                        label="Wartość"
                        fullWidth
                        validate={composeValidators(required, minValue(1), maxValue(7))}
                        parse={e => formatOnlyNumbers(e)}
                    />
                </Grid>) : null}
            </Grid>
        </>
    )
}

export default FirstLaunch;