import React from 'react'
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, Checkbox } from 'final-form-material-ui'
import {
    RadioGroup,
    FormGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'

const SensorConnections = ({ required }) => {
    return (
        <>
            <Typography variant='h2'>IV. Podłączenie czujników</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3'>4a. Podłączenie czujników temperatury: zasobnika cwu, bufora, temperatury zewnętrznej i temperatury wewętrznej</Typography>
                    <FormControl component="fieldset">
                        <FormGroup row>
                            <FormControlLabel
                                label="Czujnik zasobnika CWU"
                                control={
                                    <Field
                                        name="sensorsConnected"
                                        component={Checkbox}
                                        type="checkbox"
                                        value="CWU"
                                        color="primary"
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Czujnik bufora"
                                control={
                                    <Field
                                        name="sensorsConnected"
                                        component={Checkbox}
                                        type="checkbox"
                                        value="bufor"
                                        color="primary"
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Czujnik temperatury zewnętrznej"
                                control={
                                    <Field
                                        name="sensorsConnected"
                                        component={Checkbox}
                                        type="checkbox"
                                        value="temperatury zewnętrznej"
                                        color="primary"
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Czujnik temperatury wewnętrznej"
                                control={
                                    <Field
                                        name="sensorsConnected"
                                        component={Checkbox}
                                        type="checkbox"
                                        value="temperatury wewnętrznej"
                                        color="primary"
                                    />
                                }
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>4b. Umiejscowienie czujnika temperatury zewnętrznej</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Ściana północna"
                                control={
                                    <Field
                                        name="sensorOutsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Ściana północna"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Ściana inna niż północna - miejsce niezacienione"
                                control={
                                    <Field
                                        name="sensorOutsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="miejsce niezacienione"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Ściana inna niż północna - miejsce zacienione"
                                control={
                                    <Field
                                        name="sensorOutsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="miejsce zacienione"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="sensorOutsideTempPlace" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>4c. Umiejscowienie czujnika temperatury wewnętrznej</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Pomieszczenie reprezentatywne"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Pomieszczenie reprezentatywne"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Kuchnia"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Kuchnia"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Korytarz/Hall"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Korytarz/Hall"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Łazienka"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Łazienka"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Przy źródle ciepła"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Przy źródle ciepła"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Narażony na nasłonecznienie"
                                control={
                                    <Field
                                        name="sensorInsideTempPlace"
                                        component={Radio}
                                        type="radio"
                                        value="Narażony na nasłonecznienie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="sensorInsideTempPlace" />
                </Grid>
            </Grid>
        </>
    )
}

export default SensorConnections;