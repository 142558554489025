import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { Button, Grid, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import fetchResetPassSecond from '../../../../data/fetch/fetchResetPassSecond'

const required = value => (value ? undefined : "Pole obowiązkowe")
const passConfirm = pass => value => (value && value === pass ? undefined : "Hasła nie są jednakowe")
const minLengthPass = min => value => value === undefined || value.length < min ? `Hasło powinno się składać z minimum ${min}` : null
const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const SetNewPassword = ({ match }) => {
    const [error, setError] = useState({ status: false, message: "" })
    const [newPass, setNewPass] = useState(false)
    const [pass, setPass] = useState(null)

    const handlePass = e => {
        setPass(e.target.value)
    }

    const onSubmit = async values => {
        fetchResetPassSecond(match, values.pass)
            .then(response => {
                setNewPass(true)
            }).catch(e => {
                switch (parseInt(e.message)) {
                    case 414:
                        return setError({ status: true, message: "Podany token nie istnieje. Skorzystaj z funkcji przypomnienia hasła, aby wygenerować link z tokenem." })
                        
                    case 415:
                        return setError({ status: true, message: "Link jest przeterminowany. Skorzystaj z funkcji przypomnienia hasła jeszcze raz, aby wygenerować nowy link." })
                        
                    case 416:
                        return setError({ status: true, message: "Nieprawidłowy token. Kliknij w link, który otrzymałeś na swój adres e-mail." })

                    case 417:
                        return setError({ status: true, message: "Błąd bazy danych. Skontaktuj się z administratorem." })

                    default:
                        break;
                }
            })
    }
    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >{newPass ? <Redirect to='/pass-change' /> : null}
                <Form
                    initialValues={null}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h2" className="text-center">Resetowanie hasła</Typography>
                                    <Typography variant="body2" className="text-center">Podaj nowe hasło do konta instalatora.</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField} type="password" name="pass" label="hasło" fullWidth validate={composeValidators(minLengthPass(5), required)} onKeyUp={e => handlePass(e)} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField} type="password" name="confirm_pass" label="powtórz hasło" fullWidth validate={composeValidators(passConfirm(pass), required)} />
                                </Grid>
                            </Grid>
                            {error.status ? <Grid container spacing={2}><Grid item xs={12}><Typography variant="body2" style={{ color: "red" }}>{error.message}</Typography></Grid></Grid> : null}
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}><br />
                                    <Button
                                        variant="contained" color="primary" type="submit"
                                        disabled={submitting}>Ustaw hasło</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
            </Grid>
        </React.Fragment>
    )
}

export default SetNewPassword