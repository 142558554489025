import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { Button, Grid, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import fetchResetPassFirst from '../../data/fetch/fetchResetPassFirst'

const required = value => (value ? undefined : "Pole obowiązkowe")

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return undefined
    }
}

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const ResetPassword = () => {
    const [error, setError] = useState({ status: false, message: "" })
    const [newPass, setNewPass] = useState(false)

    const onSubmit = async values => {
        fetchResetPassFirst(values.email)
            .then(response => {
                setNewPass(true)
            }).catch(e => setError({ status: true, message: e.message }))
    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >{newPass? <Redirect to='/send-email' /> : null}
                <Form
                    initialValues={null}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h2" className="text-center">Resetowanie hasła</Typography>
                                    <Typography variant="body2" className="text-center">Podaj adres e-mail, którym logujesz się do swojego konta.</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField} type="text" name="email" label="e-mail" fullWidth validate={composeValidators(required, formatEmail)} />
                                </Grid>
                            </Grid>
                            {error.status ? <Grid container spacing={2}><Grid item xs={12}><Typography variant="body2" style={{ color: "red" }}>{error.message}</Typography></Grid></Grid> : null}
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={6}><br />
                                    <Button
                                        variant="contained" color="primary" type="submit"
                                        disabled={submitting}>Resetuj hasło</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
            </Grid>
        </React.Fragment>
    )
}

export default ResetPassword