import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const PassIsChanged = () => {
    return (
        <React.Fragment>
            <Typography variant="h2">Twoje hasło zostało zmienione.</Typography>
            <Typography variant="body2">Przejdź do logowania.</Typography>
            <Button
                variant="contained" color="primary" component={Link} to="/" style={{marginTop: "20px"}}>Logowanie</Button>
        </React.Fragment>
    )
}

export default PassIsChanged