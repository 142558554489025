import React, { useReducer } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { mainStyleTheme } from './theme'
import { Header } from './components'
import { NotExist, Login, Dashboard, ResetPassword } from './pages'
import { SendToken, PassIsChanged } from './pages/ResetPassword/components'
import { ThankYouPage, UnactiveForm} from './pages/AfterForm'
import { ReportAccepted } from './pages/AcceptReport/components'
import { default as Routing } from './Routing'
import './App.css';
import { Container, Grid } from '@material-ui/core'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { decodeToken } from "react-jwt"


const baseLoginReducer = (state, action) => {
  if (action.type === "login") {
    return { ...state, [action.type]: action.payload }
  } else if (action.type === "password") {
    return { ...state, [action.type]: action.payload }
  } else if (action.type === "token") {
    return { ...state, [action.type]: action.payload }
  } else if (action.type === "logout") {
    return { ...state, token: false }
  } else if (action.type === "perm") {
    return { ...state, perm: action.payload }
  } 
}

function App() {

  let isLogin
  let perm
  if (sessionStorage.getItem("APIToken") && sessionStorage.getItem("APIToken") !== "undefined" && sessionStorage.getItem("APIToken") !== null) {
    isLogin = sessionStorage.getItem("APIToken")
    perm = decodeToken(sessionStorage.getItem("APIToken"))
    perm = perm.user_id
  } else {
    isLogin = null
    perm = null
  }

  const [baseLogin, setBaseLogin] = useReducer(baseLoginReducer, { token: isLogin, perm: perm })

  return (
    <Router className="App">
      <ThemeProvider theme={mainStyleTheme}>
        <Header baseLogin={baseLogin} setBaseLogin={setBaseLogin} />
        <Container style={{ marginTop: "30px" }} maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <Switch>
                <Route exact path="/:type/:hash" component={Routing} />
                <Route exact path="/protokol-zaakceptowany" component={ReportAccepted} />
                <Route exact path="/"><Login baseLogin={baseLogin} setBaseLogin={setBaseLogin} /></Route>
                <Route exact path="/dziekujemy-za-rejestracje" component={ThankYouPage} />
                <Route exact path="/error" component={NotExist} />
                <Route exact path="/przeterminowany" component={UnactiveForm} />
                <Route exact path="/dashboard"><Dashboard baseLogin={baseLogin} setBaseLogin={setBaseLogin} /></Route> 
                <Route exact path="/reset"><ResetPassword /></Route> 
                <Route exact path="/send-email"><SendToken /></Route>
                <Route exact path="/pass-change"><PassIsChanged /></Route>
              </Switch>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
