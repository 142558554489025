import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField, Radio, Checkbox, Select } from 'final-form-material-ui'
import {
    Button,
    RadioGroup,
    FormGroup,
    FormControl,
    FormControlLabel,
    Grid,
    Typography, Dialog, DialogContent, IconButton, MenuItem
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../../../../../theme'
import { Redirect } from 'react-router-dom'
import formatString from "format-string-by-pattern"
import OptionList from './OptionList'
import { default as fetchNewAcceptanceReport } from '../../../../../../data/fetch/fetchNewAcceptanceReport'
import { errorMessage } from '../../../validation'
// const required = value => (value ? undefined : "Pole obowiązkowe")

const minLength = min => value => value === undefined || value.length < min ? "Podany numer jest za krótki" : null
const maxLength = max => value => value === undefined || value.length > max ? "Podany numer jest za długi" : null

const paternFAI = /^fai$/i
const paternAIO = /^aio$/i
const paternEG = /^eg$/i
const paternFAO = /^fao$/i

const formatOnlyNumbersPhone = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999-999', onlyNumbers)
}

const formatOnlyNumbersPostCode = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('99-999', onlyNumbers)
}

const deviceNumberfao = value => {
    if (value !== undefined && value.length >= 3) {
        let testFAO = paternFAO.test(value.slice(0, 3))
        if (!testFAO) {
            return "Nieprawidłowy numer seryjny urządzenia"
        } else {
            return undefined
        }
    }
}

const formatUpperCaseSn = (anyString) => {
    const onlyUpperCase = anyString.toUpperCase()
    return formatString("XXX-0000-00000000", onlyUpperCase)
}



const formatUpperCase = (anyString, modelType) => {
    const onlyUpperCase = anyString.toUpperCase()
    return formatString(modelType, onlyUpperCase)
}

const dateFormat = () => {
    const actualDate = new Date();
    let actualDateString = `${actualDate.getFullYear()}-`
    if (actualDate.getMonth() + 1 < 10) {
        actualDateString += `0${actualDate.getMonth() + 1}-`
    } else {
        actualDateString += `${actualDate.getMonth() + 1}-`
    }

    if (actualDate.getDate() < 10) {
        actualDateString += `0${actualDate.getDate()}`
    } else {
        actualDateString += `${actualDate.getDate()}`
    }

    return actualDateString;
}

const textOnlyPattern = /^[A-Za-z\s-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/

const textOnly = value => (value && textOnlyPattern.test(value) ? undefined : "Pole może zawierać tylko litery")

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

const DeviceVerification = ({ setVerifyDevice, required, installatorData, refreshInstallatorData, handleClose, setError }) => {
    const classes = mainStyleClasses()
    const actualDate = dateFormat()

    const [registrationStatus, setRegistrationStatus] = useState(null)
    const [modelType, setModelType] = useState(null)

    const deviceNumber = (value) => {
        if (value !== undefined && value.length >= 3) {
            let testAIO = paternAIO.test(value.slice(0, 3))
            let testFAI = paternFAI.test(value.slice(0, 3))
            let testEG = paternEG.test(value.slice(0, 2))

            switch (modelType.model) {
                case "ATMO":
                    if (!testFAI) {
                        return "Nieprawidłowy numer seryjny urządzenia"
                    } else {
                        return undefined
                    }
                case "GEO":
                    if (!testEG) {
                        return "Nieprawidłowy numer seryjny urządzenia"
                    } else {
                        return undefined
                    }
                case "ALLINONE":
                    if (!testAIO) {
                        return "Nieprawidłowy numer seryjny urządzenia"
                    } else {
                        return undefined
                    }
            
                default:
                    return "Nieprawidłowy numer seryjny urządzenia"
            }
            
        }
    }

    const clientPhone = (anyString) => {
        // eslint-disable-next-line no-useless-escape
        const phoneFormat = installatorData.replace('+48', '')
        const rightPhone = anyString.replaceAll('-', '').includes(phoneFormat)

        if (rightPhone) {
            return "Proszę podać numer telefonu klienta"
        } else {
            return undefined
        }
    }

    const handleOnClickModel = (e, values) => {
        if (e.target.value === "GEO")
            setModelType({ model: "GEO", mask: "XX-0000-00000000" })
        if (e.target.value === "ATMO")
            setModelType({ model: "ATMO", mask: "XXX-0000-00000000" })
        if (e.target.value === "ALLINONE")
            setModelType({ model: "ALLINONE", mask: "XXX-0000-00000000" })
    }

    const dataPack = data => {

        let dataSavePack = {
            key: data.key,
            device_sn: data.serialNumber,
            phone: data.phone,
            content: JSON.stringify(data)
        }
        if (data.modelType === "ATMO" || data.modelType === "ALLINONE"){
            dataSavePack = {...dataSavePack, outside_sn: data.serialNumberOutsideATMO}
        }

        return dataSavePack
    }

    const onSubmit = async values => {
        let val = { ...values, protocolNumber: `${11145}/${new Date().getFullYear()}` }
        console.log(val)
        setVerifyDevice(val)
        fetchNewAcceptanceReport(dataPack(val)).then(res => {
            if(res === 200){
                refreshInstallatorData()
                setError({ status: false, message: "" })
                handleClose()
            } else {
                setError({ status: true, message: errorMessage(res) })
            }
        })
    }

    return (
        <React.Fragment>
            <Grid container>
                <Form
                    initialValues={null}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='h2'>Informacje</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        name="protocolNumber"
                                        component={TextField}
                                        type="text"
                                        defaultValue={`1045/${actualDate}`}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                            disabled: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="date"
                                        component={TextField}
                                        type="text"
                                        label="Data"
                                        defaultValue={actualDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            disabled: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>

                            </Grid>


                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='h2'>Dane urządzenia</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row>
                                            <FormControlLabel
                                                label="ATMO (powietrzna)"
                                                control={
                                                    <Field
                                                        name="modelType"
                                                        component={Radio}
                                                        type="radio"
                                                        value="ATMO"
                                                        color="primary"
                                                        validate={required}
                                                        onClick={e => { handleOnClickModel(e, values); form.change('serialNumber', undefined) }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="ALLINONE (powietrzna)"
                                                control={
                                                    <Field
                                                        name="modelType"
                                                        component={Radio}
                                                        type="radio"
                                                        value="ALLINONE"
                                                        color="primary"
                                                        validate={required}
                                                        onClick={e => { handleOnClickModel(e, values); form.change('serialNumber', undefined) }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label="GEO (gruntowa)"
                                                control={
                                                    <Field
                                                        name="modelType"
                                                        component={Radio}
                                                        type="radio"
                                                        value="GEO"
                                                        color="primary"
                                                        validate={required}
                                                        onClick={e => { handleOnClickModel(e, values); form.change('serialNumber', undefined) }}
                                                    />
                                                }
                                            />
                                        </RadioGroup>

                                    </FormControl><br />

                                </Grid>
                                {modelType && modelType.mask && modelType.model ? (<React.Fragment><Grid item xs={6}>
                                    <Field
                                        name="serialNumber"
                                        component={TextField}
                                        type="text"
                                        label={modelType.model === "ATMO" || modelType.model === "ALLINONE" ? "Nr seryjny jednostki wewnętrznej" : "Numer seryjny urządzenia"}
                                        fullWidth
                                        validate={composeValidators(required, deviceNumber, minLength(12))}
                                        parse={e => formatUpperCase(e, modelType.mask)}
                                    />
                                </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name="key"
                                            component={TextField}
                                            type="text"
                                            label="Klucz urządzenia"
                                            fullWidth
                                            validate={composeValidators(required, minLength(20), maxLength(20))}
                                        />
                                    </Grid></React.Fragment>) : null}
                            </Grid>
                            <Grid container spacing={2}>
                                {modelType && (modelType.model === "ATMO" || modelType.model === "ALLINONE") ? (<React.Fragment>
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            name="serialNumberOutsideATMO"
                                            component={TextField}
                                            type="text"
                                            label="Nr seryjny jednostki zewnętrznej"
                                            fullWidth
                                            validate={composeValidators(deviceNumberfao, minLength(14), required)}
                                            parse={e => formatUpperCaseSn(e)}
                                        /></Grid></React.Fragment>) : null}
                                {modelType && modelType.model ? (
                                    <Grid item sm={6} xs={12}>
                                        <Field
                                            name="model"
                                            label="Model urządzenia"
                                            type="text"
                                            component={Select}
                                            fullWidth
                                            validate={required}
                                        >
                                            {OptionList(modelType.model).map(model => {
                                                return <MenuItem key={model} value={model}>{model}</MenuItem>
                                            })
                                            }
                                        </Field>
                                    </Grid>)
                                    : null}
                                {modelType && modelType.model ? <Grid item sm={6} xs={12}>
                                    <Field
                                        type="text"
                                        label="Typ"
                                        name="typeDevice"
                                        component={TextField}
                                        InputProps={{
                                            value: modelType.model === "ATMO" || modelType.model === "ALLINONE" ? "Zestaw Powietrznej Pompy Ciepła (ZPPC)" : "Zestaw Gruntowej Pompy Ciepła (ZGPC)",
                                            readOnly: true,
                                            disabled: true,
                                        }}
                                        fullWidth
                                    //validate={required}
                                    />
                                </Grid> : null}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='h2'>Dane instalacji</Typography>
                                    <Typography variant='h3'>Numer telefonu klienta: </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        name="phone"
                                        component={TextField}
                                        type="phone"
                                        label="Numer telefonu"
                                        fullWidth
                                        validate={composeValidators(required, minLength(11), clientPhone)}
                                        parse={e => formatOnlyNumbersPhone(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8} sm={9}>
                                    <Typography variant='h3'>Miejsce montażu: </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={8} sm={9}>
                                    <Field
                                        name="street"
                                        component={TextField}
                                        type="text"
                                        label="Ulica"
                                        fullWidth
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <Field
                                        name="streetNumber"
                                        component={TextField}
                                        type="text"
                                        label="Nr domu"
                                        fullWidth
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="city"
                                        component={TextField}
                                        type="text"
                                        label="Miejscowość"
                                        fullWidth
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="postNumber"
                                        component={TextField}
                                        type="text"
                                        label="Kod pocztowy"
                                        fullWidth
                                        validate={composeValidators(required, minLength(6))}
                                        parse={e => formatOnlyNumbersPostCode(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="postOffice"
                                        component={TextField}
                                        type="text"
                                        label="Poczta"
                                        fullWidth
                                        validate={required}
                                    />
                                </Grid>
                            </Grid>

                            {modelType ? <Grid item xs={12} className="buttons" style={{ height: "60px" }}>

                                <Button className={`${classes.floatRight} ${classes.buttonMargin}`}
                                    variant="contained" color="primary" type="submit"
                                    disabled={submitting}> Wyślij </Button>

                            </Grid> : null}
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{ marginTop: "20px" }}>* Wszystkie pola są obowiązkowe</Typography>
                                    <Typography variant="body2" className={classes.error}>{registrationStatus === 200 ? <Redirect to="/dziekujemy-za-rejestracje" /> : registrationStatus}</Typography>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
            </Grid>
        </React.Fragment>
    )
}

export default DeviceVerification