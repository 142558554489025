import React from 'react'
import { Typography, Grid } from '@material-ui/core'

const InstallerData = ({ installatorData }) => {
    return (
        <React.Fragment>
        <Grid container>
            <Grid item xs={12}>
            <Typography variant="h2">Dane instalatora:</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">Imię i nazwisko: {installatorData.first_name} {installatorData.last_name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">E-mail: {installatorData.email}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">Telefon: {installatorData.phone}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">Nr certyfikatu: {installatorData.installator_cert.cert_nr}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">Firma: {installatorData.partners[0].name}</Typography>
            </Grid>
        </Grid>
        </React.Fragment>
    )
}

export default InstallerData