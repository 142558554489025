import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import {
    Button,
    Grid,
    Typography, Dialog, DialogContent, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { mainStyleClasses } from '../../theme'
import { Redirect, Link } from 'react-router-dom'
import formatString from 'format-string-by-pattern'
import { default as fetchRegonApi } from '../../data/fetch/fetchRegonApi'
import { default as fetchRegistrationInstaller } from '../../data/fetch/fetchRegistrationInstaller'
import { default as fetchToken } from '../../data/fetch/fetchToken'

const required = value => (value ? undefined : "Pole obowiązkowe")

const textOnlyPattern = /^[A-Za-z\s-żźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/

const passConfirm = pass => value => (value && value === pass ? undefined : "Hasła nie są jednakowe")
const minLengthPass = min => value => value === undefined || value.length < min ? `Hasło powinno się składać z minimum ${min}` : null

const textOnly = value => (value && textOnlyPattern.test(value) ? undefined : "Pole może zawierać tylko litery")

const formatOnlyNumbersTel = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')

    return formatString('999-999-999', onlyNumbers)
}

const minLength = min => value => value === undefined || value.length < min ? "Podany numer jest za krótki" : null
const maxLength = max => value => value === undefined || value.length > max ? "Podany numer jest za długi" : null

const formatEmail = (anyString) => {
    // eslint-disable-next-line no-useless-escape
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i
    const onlyEmail = emailFormat.test(anyString)

    if (!onlyEmail) {
        return "Nieprawidłowy adres e-mail"
    } else {
        return undefined
    }
}

const validNipNumber = (anyString) => {
    const nipString = anyString.replace(/[\ \-]/gi, '')
    const nipWieght = [6, 5, 7, 2, 3, 4, 5, 6, 7]

    let nipSum = 0

    if (nipString.length < 10) {
        return "Podany numer jest za krótki"
    } else if (nipString.length > 10) {
        return "Podany numer jest za długi"
    } else {

        for (let i = 0; i < nipWieght.length; i++) {
            nipSum += (parseInt(nipString.slice(i, i + 1)) * nipWieght[i])
        }

        if (nipSum % 11 === parseInt(anyString.slice(9, 10))) {
            return undefined
        } else {
            return "Nieprawidłowy numer nip"
        }
    }
}

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);


const Registration = ({ match }) => {
    const classes = mainStyleClasses()

    const [registrationStatus, setRegistrationStatus] = useState(null)
    const [pass, setPass] = useState(null)
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handlePass = e => {
        setPass(e.target.value)
    }

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    const dataPack = (val, token) => {

        const pack = {
            password: val.password,
            token: token,
            cert_nr: val.certificate

        }

        return pack
    }
    const onSubmit = async values => {
        // await sleep(300)
        // window.alert(JSON.stringify(values, 0, 2))
        fetchToken(dataPack(values, match)).then(response => {
                    switch (response) {
                        case 200:
                            return setRegistrationStatus(200)
                        case 414:
                            return setRegistrationStatus('Konto nie istnieje.')
                        case 415:
                            return setRegistrationStatus('Formularz wygasł.')
                        case 416:
                            return setRegistrationStatus('Błędny adres formularza.')
                        case 419:
                            return setRegistrationStatus('Niepoprawny numer certyfikatu.')
                        default:
                            return setRegistrationStatus('Wystąpił błąd przesyłania danych')
                    }
                })
        setOpen(true)

    }

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography variant="h1">Ustawienie hasła do konta:</Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Form
                    initialValues={null}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, form, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                {/*<Grid item xs={12} md={6}>
                                    <Field
                                        name="email"
                                        component={TextField}
                                        type="email"
                                        label="Adres e-mail"
                                        fullWidth
                                        validate={composeValidators(required, formatEmail)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        name="phone"
                                        component={TextField}
                                        type="phone"
                                        label="Numer telefonu"
                                        fullWidth
                                        validate={composeValidators(required, minLength(11))}
                                        parse={e => formatOnlyNumbersTel(e)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="first_name"
                                        component={TextField}
                                        type="first_name"
                                        label="Imię"
                                        fullWidth
                                        validate={composeValidators(required, textOnly)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="last_name"
                                        component={TextField}
                                        type="last_name"
                                        label="Nazwisko"
                                        fullWidth
                                        validate={composeValidators(required, textOnly)}
                                    />
                    </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        name="nip"
                                        component={TextField}
                                        type="nip"
                                        label="NIP"
                                        fullWidth
                                        validate={composeValidators(required, minLength(10), maxLength(12), validNipNumber)}
                                    />
                                </Grid>*/}
                                <Grid item xs={12} md={6}>
                                    <Field
                                        name="certificate"
                                        component={TextField}
                                        type="certificate"
                                        label="Nr certyfikatu"
                                        fullWidth
                                        //validate={composeValidators(required, minLength(10), maxLength(12), validNipNumber)}
                                    />
                                </Grid>


                            </Grid>
                            <React.Fragment>
                                
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="password"
                                            component={TextField}
                                            type="password"
                                            label="Wpisz hasło"
                                            fullWidth
                                            validate={composeValidators(minLengthPass(5), required)}
                                            onKeyUp={e => handlePass(e)}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="passwordConfirm"
                                            component={TextField}
                                            type="password"
                                            label="Powtórz hasło"
                                            fullWidth
                                            validate={composeValidators(passConfirm(pass), required)}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>

                            <Grid item xs={12} className="buttons" style={{ height: "60px" }}>

                                <Button className={`${classes.floatRight} ${classes.buttonMargin}`}
                                    variant="contained" color="primary" type="submit"
                                    disabled={submitting}> Zapisz </Button>

                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className={classes.error} style={{ marginTop: "20px" }}>* Ustawienie hasła jest niezbędne do korzystania z panelu</Typography>
                                    <Typography variant="body2" className={classes.error}>{registrationStatus === 200 ? <Redirect to="/dziekujemy-za-rejestracje" /> : registrationStatus}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}><br /><br />
                                    <Typography variant="body1">Posiadasz już konto?</Typography><br />
                                    <Button variant="contained" color="primary" type="button" component={Link} to='/'> Przejdź do logowania </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <Dialog scroll={"body"} fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogContent style={{ padding: "10px 25px 25px" }}>
                        <IconButton aria-label="close" onClick={handleClose} className={classes.floatRight} style={{ zIndex: "99", position: "absolute", top: "10px", right: "15px" }}>
                            <CloseIcon />
                        </IconButton>

                        <Typography variant="h2">Nie można wysłać danych.</Typography>
                        <Typography variant="body2">{registrationStatus}</Typography>
                    </DialogContent>
                </Dialog>
            </Grid>

        </React.Fragment>
    )
}

export default Registration