import React from 'react'
import { Field } from 'react-final-form'
import { mainStyleClasses } from '../../../../../../../../theme'

const Error = ({ name }) => {
  const classes = mainStyleClasses()
  return (
    <Field
      name={name}
      subscribe={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span className={classes.error}>{error}</span> : null
      }
    />
  )}

export default Error;