const fetchResetPassSecond = async (token, pass) => {
    const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/installators/reset_password/second`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        }, 
        body: JSON.stringify({token: token, password: pass})
    }).then(res => {
        if (res.ok) {
            return res.json()
        } else {
            throw new Error(res.status)
        }
    })


    return response
}

export default fetchResetPassSecond