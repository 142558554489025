const fetchFile = async (hash, payload) => {
    const response = fetch(`${process.env.REACT_APP_FETCH_DEV}rest/acceptance_report_files/upload/token/${hash}`, {
        method: "POST",
        body: payload
    }).then(response => response.status)
    // console.log(hash)
    // console.log(payload)
    return response
}

export default fetchFile