const fetchToken = async (payload) => {
    const response = fetch(`${process.env.REACT_APP_FETCH_DEV}rest/installators/register/post`, {
        method: "POST",
        headers:{
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
    }).then(response => response.status)

    return response
}

export default fetchToken