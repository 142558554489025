import React from 'react'
import { Typography } from '@material-ui/core'

const SendToken = () => {
    return (
        <React.Fragment>
            <Typography variant="h2">Link do aktualizacji hasła został wysłany na podany adres e-mail.</Typography>
            <Typography variant="body2">Przejdź do swojej skrzynki mailowej, klinij w link i ustaw nowe hasło.</Typography>
            <Typography variant="body2">Jeśli nie widzisz wiadomości sprawdź folder spam.</Typography>
        </React.Fragment>
    )
}

export default SendToken