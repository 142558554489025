import { default as fetchRefreshToken } from './fetchRefreshToken'

const fetchPin = async (device_sn) => {
    const response = await fetchRefreshToken().then(async newToken => {
      
      const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/acceptance_report/device_sn/${device_sn}/pin`, {
        method: "GET",
        headers:{
          "Authorization": `Bearer ${newToken}`
        }
      })
      .then(response => response.json())
      return res
    })
    return response
}

  export default fetchPin
