import React from 'react'
import { Button, Grid, Box, Typography } from '@material-ui/core'
import { Form, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { Redirect } from 'react-router-dom'
import { mainStyleClasses } from '../../../../theme'
import { default as fetchAcceptanceRepport } from '../../../../data/fetch/fetchAcceptanceReport'

const CommentForm = ({ handleClose, match, setAcceptStatus }) => {
    const classes = mainStyleClasses()

    const onSubmit = async values => {
        fetchAcceptanceRepport(match, "PUT", values).then(res => console.log(res))
        setAcceptStatus({ status: "ok", data: "accept" })
        handleClose()
    }
    
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">Prosimy o wpisanie swoich uwag i przesłanie ich do nas</Typography>
                </Grid>
            </Grid>
            <Form
                initialValues={null}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, form, submitting, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Field
                                    name="customer_content"
                                    component={TextField}
                                    type="customer_content"
                                    label="Uwagi dotyczące protokołu uruchomienia"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className="buttons" style={{ height: "60px" }}>
                            <Button className={`${classes.floatRight} ${classes.buttonMargin}`}
                                variant="contained" color="primary" type="submit"
                                disabled={submitting}> Wyślij uwagi </Button>
                        </Grid>
                    </form>
                )}
            </Form>
        </React.Fragment>
    )
}

export default CommentForm