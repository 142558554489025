import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { CreatePdf } from '../'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { default as fetchFile } from '../../../../data/fetch/fetchFile'

const AfterSend = ({ report, match }) => {
    const blobSend = (blob) => {
        let pdfFile = new FormData()
        pdfFile.append(`file`, blob)
        pdfFile.append('file_type', 'application/pdf')
        pdfFile.append('filename', `protokol-odbioru-${JSON.parse(report.content).protocolNumber}.pdf`)
        pdfFile.append('orig_file_name', `protokol-odbioru-${JSON.parse(report.content).protocolNumber}.pdf`)

        // console.log(pdfFile)
        fetchFile(match, pdfFile)
            .then(res => console.log(res))
    }
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h1">Dziękujemy za zapoznanie się z protokołem pierwszego uruchomienia.</Typography>
                    <Typography variant="h3">Zapraszamy do pobrania aplikacji mobilnej do zarządzania pompą. Hasło do aplikacji możesz ustanowić za pomoca poniższego przycisku.</Typography>

                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <br /><Button variant="contained" color="primary" type="button" component={Link} to={{ pathname: "https://cloud.eurosenergy.com:8801/" }} target="_blank">Ustanowienie hasła</Button>
                </Grid>
                <Grid item xs={6}>
                    <br /><Button variant="contained" color="primary">
                        {report ? (<PDFDownloadLink style={{ color: "#ffffff" }} document={<CreatePdf values={JSON.parse(report.content)} />} fileName={`protokol-odbioru-${JSON.parse(report.content).protocolNumber}.pdf`}>
                            {({ blob, url, loading, error }) => {
                                if (!loading)
                                    blobSend(blob)
                                return (loading ? 'Ładowanie dokumentu...' : 'Zapisz PDF')
                            }
                            }
                        </PDFDownloadLink>) : null}
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AfterSend