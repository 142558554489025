import React, { useState } from 'react';
import { Field } from 'react-final-form'
import { Error } from '../components'
import { Radio, TextField } from 'final-form-material-ui'
import {
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Grid
} from '@material-ui/core'
import formatString from "format-string-by-pattern"

const maxValue = max => value =>
  isNaN(value) || value <= max ? undefined : `Wartość powinna wynosić nie wiecej niż ${max}`

  const formatOnlyNumbers = (anyString) => {
    const onlyNumbers = anyString.replace(/[^\d]/g, '')
  
    return formatString('-999', onlyNumbers)
  }


const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)


const PreparationFirstLaunch = ({ required, setValues, coagulationTemp }) => {
    const [controlCoagulationTemp, setControlCoagulationTemp] = useState(false)

    const handleControlCoagulationTemp = (e) => {
        setControlCoagulationTemp(e)
    }

    return (
        <>
            <Typography variant='h2'>VI. Przygotowanie do pierwszego uruchomienia</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3'>6a. Pierwsze czyszczenie filtrów w obiegu grzewczym</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="cleanFilter"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="cleanFilter"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="cleanFilter" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h3'>6b. Kontrola temperatury krzepnięcia w obiegu grzewczym</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="controlCoagulationTemp"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                        onClick={() => {setValues({type: "coagulationTemp", value: true})}}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="controlCoagulationTemp"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                        onClick={() => {setValues({type: "coagulationTemp", value: false})}}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="controlCoagulationTemp" />
                </Grid>
                {coagulationTemp? (<Grid item xs={12} sm={6}>
                    <Field
                        name="coagulationTemp"
                        component={TextField}
                        type="text"
                        label="Temperatura krzepnięcia (&#x2103;)"
                        fullWidth
                        validate={composeValidators(required, maxValue(0))}
                        parse={e => formatOnlyNumbers(e)}
                    />
                </Grid>) : null}
                <Grid item xs={12}>
                    <Typography variant='h3'>6c. Załączenie bezpiecznika jednostki zewnętrznej</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row>
                            <FormControlLabel
                                label="Wykonano"
                                control={
                                    <Field
                                        name="outdoorFuse"
                                        component={Radio}
                                        type="radio"
                                        value="Tak"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Nie wykonano"
                                control={
                                    <Field
                                        name="outdoorFuse"
                                        component={Radio}
                                        type="radio"
                                        value="Nie"
                                        color="primary"
                                        validate={required}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl><br />
                    <Error name="outdoorFuse" />
                </Grid>
            </Grid>
        </>
    )
}

export default PreparationFirstLaunch;