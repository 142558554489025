import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Button } from '@material-ui/core'
import { mainStyleClasses } from '../../../../../../theme'
import { ConfirmSendForm } from '../../components/'
import arrayMutators from 'final-form-arrays'



const Wizard = ({ children, onSubmit, initialValues}) => {
  const classes = mainStyleClasses()

  const [values, setValues] = useState(initialValues);
  const [page, setPage] = useState(0);
console.log({values})
  const next = values => {
    setPage(Math.min(page + 1, children.length - 1))
    setValues(values)
    }

  const previous = () =>{
      setPage(Math.max(page -1, 0))
  }

  const validate = values => {
    const activePage = React.Children.toArray(children)[
      page
    ]
    
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  const handleSubmit = values => {
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      return onSubmit(values)
    } else {
      next(values)
    }
  }

    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1
    return (
      <Form
        initialValues={values}
        validate={validate}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators
        }}
      >
        {({ handleSubmit, submitting, pristine, values, form }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            {isLastPage && (
                <ConfirmSendForm values={values} />
            )}
            <div className="buttons">
              {page > 0 && (
                <Button className={`${classes.floatLeft} ${classes.buttonMargin}`} variant="contained" color="primary" onClick={previous}> « Cofnij </Button>
              )}
              {!isLastPage && <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary" type="submit"> Dalej » </Button>}
              {/*values.userInstruct && !isLastPage? <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary" type="submit" onClick={() => form.change('status', 'filled')} disabled={submitting}>Zatwierdź</Button> : null*/}
              {isLastPage && (
                <React.Fragment>
                  <Button className={`${classes.floatRight} ${classes.buttonMargin}`} style={{marginLeft: "15px"}} variant="contained" color="primary" type="submit" onClick={() => form.change('status', 'filled')} disabled={submitting}>Zatwierdź</Button>
                  <Button className={`${classes.floatRight} ${classes.buttonMargin}`} variant="contained" color="primary" type="submit" onClick={() => form.change('status', 'new')} disabled={submitting}> Zapisz </Button>
                </React.Fragment>
              )}
            </div>
          </form>
        )}
      </Form>
    )
}


Wizard.propTypes = {
    onSubmit: PropTypes.func.isRequired
  }

Wizard.Page = ({ children }) => children


export default Wizard;